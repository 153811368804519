import React from "react";
import { Link } from "gatsby";
import Img01 from "gatsby-theme-starberry-lomondgroup/src/images/advice-img.jpg"
import "gatsby-theme-starberry-lomondgroup/src/components/Advice/Advice.scss";
import { PageLinks } from "gatsby-theme-starberry-lomondgroup/src/common/page-links";

const Advice = () => {


    return (
        <React.Fragment>
            <section className="advice">
                <div className="advice-wrap">
                    <div className="img-wrap">
                        <img src={Img01} alt="advice" />
                    </div>
                    <div className="advice-content">
                        <div className="h4">
                            Get free mortgage advice<span className="sm-text">One of our team will be in touch to discuss your requirements.</span> 
                        </div>
                    </div>
                    <div className="btn-wrap">
                        <Link to={`/${PageLinks.financial_services}/`} className="btn mortgage-btn">Get mortgage advice</Link>
                    </div>
                </div>

            </section>
        </React.Fragment>
    );
};
export default Advice;
