import React, {useState, useEffect, useRef} from "react";
import { Container, Row, Col} from "react-bootstrap";
import "gatsby-theme-starberry-lomondgroup/src/components/PropertyBanner/PropertyBanner.scss";
import ImageCallBack from "gatsby-theme-starberry-lomondgroup/src/modules/image-callback-module";
import SearchResultsImagePopup from "gatsby-theme-starberry-lomondgroup/src/components/SalesCard/SearchResultsImagePopup"
import Slider from "react-slick";
import $ from "jquery";
import chunk from "lodash/chunk";

const Banner = ({property_data, clickFloorplan, setPlay, videoUrl}) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        const updateDeviceType = () => {
            const mobileQuery = window.matchMedia("(max-width: 767px)");
            const tabletQuery = window.matchMedia("(max-width: 1024px)");

            setIsMobile(mobileQuery.matches);
            setIsTablet(tabletQuery.matches);
        };

        // Run on mount
        updateDeviceType();

        // Add event listeners
        const resizeListener = () => updateDeviceType();
        window.addEventListener("resize", resizeListener);

        // Cleanup
        return () => window.removeEventListener("resize", resizeListener);
    }, []);

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);
    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);

    var settings = {
        lazyLoad: "progressive",
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        fade: true,
        swipe: false,
        afterChange: (currentSlide) => {
            const dotsContainer = $('.property-slider-large .slick-slider').find('.slick-dots');
            const dots = dotsContainer.find('li');
            
            // Remove existing classes
            dots.removeClass('prev next');
    
            // Add classes to previous and next dots relative to the active dot
            const activeDot = dots.eq(currentSlide);
            activeDot.prev().addClass('prev');
            activeDot.next().addClass('next');
            
            // Scroll the active dot into view
            if (activeDot.get(0)) {
                activeDot.get(0).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        },
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots:true,
                    swipe: true,
                },
            },

        ],
    };
    var navSettings = {
        lazyLoad: "progressive",
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: false,
        swipe: false,
    };

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    } 

    const trackerBrochure = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Download Brochure',
            'formType': event + ' - ' + shareurl,
            'formId': 'Brochure',
            'formName': 'Brochure',
            'formLabel': 'Brochure'
        });
    }

    const ImageRenderList = ({item, image_url, imagename}) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.images_Transforms) {
            processedImages = item?.imagetransforms?.images_Transforms;
        }
        return (
          <ImageCallBack 
            processedImages={processedImages} 
            imageSrc={image_url} 
            imagename={imagename} 
            strapi_id={item.id}
            altText={`${item.display_address}`}
            renderer="srcSet"
            classNames=""
            ggfx_results={item?.ggfx_results}
        />                          
        )
    }

    var images_filter = property_data?.images?.length > 0 && property_data?.images?.filter(img => img?.url && img.url !== null)  

    var home_report = property_data?.brochure?.length > 0 ? property_data?.brochure[0] : "";

    var brochure_url = property_data?.selling_info?.publicBrochureUrl ? property_data?.selling_info?.publicBrochureUrl  : property_data?.brochure?.length > 0 ? property_data?.brochure[0]?.url : "";

    return (
        <React.Fragment>
            <section className="property-banner">
                <Container>
                    <Row>
                        <Col>
                            <div className="property-slider">
                                <div className="property-slider-large">
                                    <Slider {...settings} asNavFor={!isTablet ? nav2 : ''} ref={slider => (sliderRef1 = slider)}>
                                        {images_filter.map((item, index) => (
                                            <div className={`property-image image-${index}`} key={index}>
                                                {(index===0 || index === images_filter.length - 1) ?
                                                <ImageRenderList
                                                    item={property_data}
                                                    image_url={item}
                                                    imagename="property.images.details_large"
                                                />
                                                :
                                                <img src={item.url} alt={`${property_data.display_address}`} />
                                                }
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                {!isTablet &&
                                <div className="property-slider-small">
                                    <Slider {...navSettings} asNavFor={nav1} ref={slider => (sliderRef2 = slider)}>
                                        {[...images_filter.slice(1), images_filter[0]].map((item, index) => (
                                            <div className={`property-image image-${index}`} key={index}>
                                                {(index===0 || index===1) ?
                                                <ImageRenderList
                                                    item={property_data}
                                                    image_url={item}
                                                    imagename="property.images.details_large"
                                                />
                                                :
                                                <img src={item.url} alt={`${property_data.display_address}`} />
                                                }
                                            {console.log(item)}
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                }
                                <div className="banner_bottom">
                                    {images_filter.length > 0 && 
                                        <a href="javascript:;" className="bottom-text" onClick={(e) => openLighboxMobile(e,0)}>
                                            <i className="icon-camera"></i>
                                            {images_filter.length > 1 ?
                                                <span className="text">1/{images_filter.length}</span>
                                                :
                                                <span className="text">{images_filter.length}</span>
                                            }
                                        </a>
                                    }

                                    {property_data?.floorplan?.length > 0 &&
                                        <a href="javascript:;" className="bottom-text" onClick={() => clickFloorplan("floor_plan")}>
                                            <i className="icon-floor"></i>
                                            <span className="text">Floorplan</span>
                                        </a>
                                    }

                                    {videoUrl &&

                                        <a href="javascript:;" className="bottom-text" onClick={e => { setPlay(true) }}>
                                            <i className="icon-video-camera"></i>
                                            <span className="text">Video Tour</span>
                                        </a>
                                    }

                                    {home_report &&
                                        <a href={home_report} target={"_blank"} onClick={() => trackerBrochure('Home Report')} className="bottom-text" >
                                            <i className="home_icon"></i>
                                            <span className="text d-none d-md-flex">Home Report</span>
                                            <span className="text d-md-none">Report</span>
                                        </a>
                                    }

                                    {brochure_url &&
                                        <a href={brochure_url} target={"_blank"} onClick={() => trackerBrochure('Brochure')} className="bottom-text d-none d-md-flex" >
                                            <i className="brochure"></i>
                                            <span className="text">Brochure</span>
                                        </a>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {isOpen && (images_filter && images_filter?.length != 0) && (
                <SearchResultsImagePopup
                    propertyId = {property_data.id}
                    propertyImage = {images_filter}
                    photoIndex={photoIndex}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    img_type = {"property_details"}
                    property_data={property_data}
                />
            )}
        </React.Fragment>
    );
};
export default Banner;
